import { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommonModal } from '@/entities/modal';
import { useUser, useUserId } from '@/entities/user';
import { useNamespace } from '@/shared/lib/hooks/use-namespace.hook';
import { PageLoader } from '@/widgets/page';

import { useGetUserBoatsQuery } from '../../api/boat.api';

const BoatGateComponent = ({ children }: PropsWithChildren) => {
	const userId = useUserId();
	const { acceptedLatestAgreements } = useUser();
	const { data, isLoading } = useGetUserBoatsQuery(userId);
	const [openWarningModal] = useCommonModal();
	const namespace = useNamespace('common');
	const { t } = useTranslation(namespace as ['plant', 'common'] | 'common');
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (data?.length === 0 && !mounted && acceptedLatestAgreements) {
			openWarningModal({
				title: t('common:modal.single.title'),
				description: t('modal.boat.empty'),
			});
			setMounted(true);
		}
	}, [data?.length, mounted, openWarningModal, t, acceptedLatestAgreements]);

	if (isLoading) {
		return <PageLoader />;
	}

	return <>{children}</>;
};

export default BoatGateComponent;
