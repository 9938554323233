import 'pdfjs-dist/build/pdf.worker.entry';

import * as pdfjsLib from 'pdfjs-dist';
import React, { useEffect, useState } from 'react';

import { Box } from '@/shared/ui/box';
import { Spinner } from '@/shared/ui/spinner';

import { PdfToImageViewerBox } from './pdf-to-image-viewer.styles';
import { PdfToImageViewerProps } from './pdf-to-image-viewer.types';

const PdfToImageViewer = ({ pdfUrl, fullscreen }: PdfToImageViewerProps) => {
	const [images, setImages] = useState<string[]>([]);

	const handleContextMenu = (e: React.MouseEvent) => e.preventDefault();

	const handleCopy = (event: ClipboardEvent) => {
		event.preventDefault();
		if (event.clipboardData) {
			event.clipboardData.setData('text/plain', window.location.origin);
		}
	};

	useEffect(() => {
		const loadPdfAndConvertToImages = async () => {
			const loadingTask = pdfjsLib.getDocument(pdfUrl);
			const pdf = await loadingTask.promise;
			const pages = [];

			for (let i = 1; i <= pdf.numPages; i++) {
				const page = await pdf.getPage(i);

				const viewport = page.getViewport({ scale: 2 });
				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');

				if (context) {
					canvas.width = viewport.width;
					canvas.height = viewport.height;

					const renderContext = {
						canvasContext: context,
						viewport: viewport,
					};

					await page.render(renderContext).promise;
				}

				pages.push(canvas.toDataURL());
			}

			setImages(pages);
		};

		loadPdfAndConvertToImages().catch(console.error);

		document.addEventListener('copy', handleCopy);

		return () => {
			document.removeEventListener('copy', handleCopy);
		};
	}, [pdfUrl]);

	if (!images.length) {
		return (
			<Box justify="center">
				<Spinner />
			</Box>
		);
	}

	return (
		<PdfToImageViewerBox fullscreen={fullscreen} onContextMenu={handleContextMenu}>
			{images.map((imgSrc, index) => (
				<img key={index} src={imgSrc} alt={`Page ${index + 1}`} width="100%" />
			))}
		</PdfToImageViewerBox>
	);
};

export default PdfToImageViewer;
