import styled from 'styled-components';

import { Box } from '@/shared/ui/box';

export const PdfToImageViewerBox = styled(Box)<{ fullscreen: boolean }>`
	display: flex;
	flex-direction: column;
	height: ${({ fullscreen }) => `calc(100vh - ${fullscreen ? 475 : 600}px)`};
	overflow: auto;

	& img {
		pointer-events: none;
	}
`;
