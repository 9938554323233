import { memo } from 'react';

import { Box } from '@/shared/ui/box';
import { Checkbox } from '@/shared/ui/checkbox';
import { Text } from '@/shared/ui/text';

import { CheckboxListComponentProps } from './checkbox-list.types';

const CheckboxListComponent = memo(({ checkboxes, checkedState, onChange }: CheckboxListComponentProps) => {
	return checkboxes.map((checkbox, index) => (
		<Box key={index} gap={6}>
			<Checkbox alignItems="start" checked={checkedState[index]} onChange={() => onChange(index)} />
			<Box gap={4}>
				{typeof checkbox.text === 'string' && <Text label={checkbox.text} />}
				{typeof checkbox.text !== 'string' && checkbox.text}
			</Box>
		</Box>
	));
});

export default CheckboxListComponent;
