import styled from 'styled-components';

import { Box } from '@/shared/ui/box';

export const ScrollableBox = styled(Box)<{ fullscreen: boolean; isMobile: boolean }>`
	height: ${({ isMobile }) => isMobile && '100%'};

	& > div:nth-child(2) > div:nth-child(2) {
		flex-direction: ${({ isMobile }) => isMobile && 'column'};
	}
`;
