import { memo } from 'react';

import { useMatchMedia } from '@/shared/lib/hooks/use-match-media.hook';

import { useModals } from '../../model/selectors/select-modals';
import { useModalActions } from '../../model/slice/modal.slice';
import * as Styled from './modal-container.styles';

const ModalContainerComponent = () => {
	const modals = useModals();
	const { removeModal } = useModalActions();
	const isMobile = useMatchMedia('mobile');

	if (!modals.length) {
		return null;
	}

	return (
		<>
			{modals.map(({ id, component: Component, isOverlayClose }) => {
				const handleClose = () => {
					if (isOverlayClose) {
						removeModal(id);
					}
				};

				if (isMobile) {
					return <Component key={id} />;
				}

				return (
					<Styled.Dialog key={id} open onClose={() => {}} onClick={handleClose}>
						<Styled.Panel className="panel">
							<Component />
						</Styled.Panel>
					</Styled.Dialog>
				);
			})}
		</>
	);
};

export default memo(ModalContainerComponent);
