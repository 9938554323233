import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@/shared/ui/box';
import { DatePicker } from '@/shared/ui/date-picker';

import { RangePickerProps } from './range-picker.types';

const RangePickerComponent = ({
	onChangeStartDate,
	onChangeEndDate,
	startDate,
	endDate,
	startDateLabel = '',
	endDateLabel = '',
	marginLeft = 'auto',
	marginRight = 'none',
	marginBottom = '32px',
	maxWidth = 'l',
	minWidth = 'l',
	startDateError,
	endDateError,
	padding,
	flexWrap,
	flex,
	showTimeInput = false,
	dateFormat,
	direction,
	timeCaption = 'Time',
	showTimeSelect = false,
	filterTime,
	highlightDates,
	isNextDisabled = false,
	onKeyDown,
}: RangePickerProps) => {
	const { t } = useTranslation('common');

	return (
		<Box
			gap={24}
			marginLeft={marginLeft}
			marginRight={marginRight}
			marginBottom={marginBottom}
			flexWrap={flexWrap}
			flex={flex}
			padding={padding}
			direction={direction}
		>
			<DatePicker
				value={startDate}
				onChange={onChangeStartDate}
				startDate={startDate}
				endDate={endDate}
				placeholder={t('datepicker.from.placeholder')}
				label={startDateLabel}
				dateFormat={dateFormat}
				selectsStart
				showTimeInput={showTimeInput}
				maxWidth={maxWidth}
				minWidth={minWidth}
				error={startDateError}
				timeCaption={timeCaption}
				showTimeSelect={showTimeSelect}
				filterTime={filterTime}
				highlightDates={highlightDates}
				isNextDisabled={isNextDisabled}
				onKeyDown={onKeyDown}
			/>
			<DatePicker
				value={endDate}
				onChange={onChangeEndDate}
				startDate={startDate}
				endDate={endDate}
				minDate={startDate}
				placeholder={t('datepicker.to.placeholder')}
				label={endDateLabel}
				dateFormat={dateFormat}
				selectsEnd
				showTimeInput={showTimeInput}
				maxWidth={maxWidth}
				minWidth={minWidth}
				error={endDateError}
				timeCaption={timeCaption}
				showTimeSelect={showTimeSelect}
				filterTime={filterTime}
				highlightDates={highlightDates}
				isNextDisabled={isNextDisabled}
				onKeyDown={onKeyDown}
			/>
		</Box>
	);
};

export default memo(RangePickerComponent);
